import { graphql, useStaticQuery } from 'gatsby';
import JavaIcon from 'svgs/single-case-study/java-development.svg';
import MobileDevelopmentIcon from 'svgs/single-case-study/mobile-development.svg';
import ReactJsIcon from 'svgs/single-case-study/react-js.svg';
import UxUiDesign from 'svgs/single-case-study/ux-ui-design.svg';

import { contentResolver } from '../utilities';

const faqPath = 'faq-section.content';
const FAQ_ITEMS = [
    {
        question: `${faqPath}.q1`,
        answer: `${faqPath}.a1`,
    },
    {
        question: `${faqPath}.q2`,
        answer: `${faqPath}.a2`,
    },
    {
        question: `${faqPath}.q3`,
        answer: `${faqPath}.a3`,
    },
    {
        question: `${faqPath}.q4`,
        answer: `${faqPath}.a4`,
    },
    {
        question: `${faqPath}.q5`,
        answer: `${faqPath}.a5`,
    },
];

const content = contentResolver({
    project: 'heyway',
    brandColor: '--heyway-case-study',
    brandColorGradient: {
        start: '--heyway-case-study-gradient-start',
        stop: '--heyway-case-study-case-end',
    },
    scopeOfWork: [
        {
            title: 'scopeOfWork.mobileDevelopment',
            Icon: MobileDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.javaDevelopment',
            Icon: JavaIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiDesign,
        },
        {
            title: 'scopeOfWork.reactNative',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.iOSAndroid',
            Icon: MobileDevelopmentIcon,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
    },
    paragraphsCounters: {
        header: 2,
        about: 4,
        challenge: 3,
        mainGoals: 3,
        solutions: 3,
        summary: 3,
    },
    caseStudies: ['nftReality', '4Tipsters', 'letempick'],
    caseStudiesSubtitle: 'case-studies.subtitle',
    faqTitle: 'faq-section.title',
    faqData: FAQ_ITEMS,
});

content.headerImageAlt = 'social media app development';
content.about.alt = 'develop a social media app';
content.challenge.alt = 'develop a social media app project';
content.mainGoals.alt = 'social media app development project';
content.solutions.alt = 'social media application development project';
content.summary.alt = 'develop a social media application';

export const useHeyway = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: { eq: "single-case-study/heyway/header.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: { eq: "single-case-study/heyway/about.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/heyway/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/heyway/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/heyway/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: { eq: "single-case-study/heyway/summary.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
